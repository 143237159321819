<template>
  <v-row dense>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row no-gutters class="mb-2">
            <v-col>
              <ers-table title="접수내역" :customers="customers" @select="selectItem" ref="ersTable" />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="!ersItem || ersItem.custCode!=='SCAR01'">
            <v-col cols="auto">
              <kakao-map title="요청위치" :center="mapCenter" :agent-markers="agents" :level="mapLevel" @zoom="onZoomChanged" />
            </v-col>
            <v-col class="auto ml-1">
              <ers-detail :ersItem.sync="ersItem" :customers="customers" @refresh="refresh" @newForm="ersItem = {}" @map-center="changeMapCenter" />
            </v-col>
            <v-col class="auto ml-1">
              <ers-work :ersItem.sync="ersItem" @refresh="refresh" />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="ersItem && ersItem.custCode==='SCAR01'">
            <v-col>
              <ers-history title="진행내역" :ersItem.sync="ersItem" />
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/services/api'
import { mapGetters } from 'vuex'
import vs from '@/utils/viewSupport'
export default {
  name: 'Dashboard',
  metaInfo: {
    title: '대시보드'
  },
  data() {
    return {
      mapCenter: null,
      ersItem: null,
      customers: [],
      agents: [],
      mapLevel: 4
    }
  },
  methods: {
    selectItem(custCode, item) {
      this.mapCenter = null
      if (item.location) {
        const loc = item.location.split(',')
        if (loc.length > 1 && loc[0] !== '0' && loc[1] !== '0') {
          this.mapCenter = { longitude: loc[0], latitude: loc[1] }
          api.getAgentsByErsLocation(loc[0], loc[1]).then(r => { this.agents = r.result })
        }
      }
      this.ersItem = item
    },
    refresh() {
      this.$refs.ersTable.list()
    },
    onZoomChanged(level) {
      if (this.ersItem && this.mapCenter) {
        api.getAgentsByErsLocation(this.mapCenter.longitude, this.mapCenter.latitude, vs.mapLevelToDistance(level)).then(r => { this.agents = r.result })
      }
    },
    changeMapCenter(loc) {
      this.mapCenter = loc
    }
  },
  created() {
    this.$store.commit('setAppBarTitle', '대시보드')
  },
  mounted() {
    api.listCustomers().then(r => { this.customers = r.result })
  },
  watch: {
    newRequest() {
      console.log('newRequest', this.newRequest)
      this.refresh()
    }
  },
  computed: {
    ...mapGetters(['newRequest'])
  },
  components: {
    KakaoMap: () => import('@/views/components/dashboard/KakaoMap'),
    ErsTable: () => import('@/views/components/dashboard/ErsTable'),
    ErsDetail: () => import('@/views/components/dashboard/ErsDetail'),
    ErsWork: () => import('@/views/components/dashboard/ErsWork'),
    ErsHistory: () => import('@/views/components/dashboard/ErsHistory')
  }
}
</script>
